import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverUrl } from '../config';
import google from '../assets/google.png';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(`${serverUrl}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        const token = data.access_token;
        const expiresIn = data.expires_in;
        const expirationTime = new Date().getTime() + expiresIn * 1000;
        
        localStorage.setItem('token', token);
        localStorage.setItem('tokenExpiration', expirationTime);
  
        setSuccess(data.message);
        setError('');
        navigate('/services');
      } else {
        setError(data.error);
        setSuccess('');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      setSuccess('');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await fetch(`${serverUrl}/auth/login/google`, {
        method: 'GET',
        credentials: 'include',
      });
  
      const data = await response.json();
  
      if (response.ok) {
        window.location.href = data.authorization_url;
      } else {
        setError('Failed to initiate Google login.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <>
      <div className="flex justify-center items-center bg-blue-50 max-md:px-5 min-h-screen pt-2">
        <div className="flex flex-col mt-5 w-full max-w-[900px] max-md:max-w-full">
          <div className="py-10 px-10 bg-white rounded-3xl border border-solid border-blue-500 border-opacity-50 max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col items-center w-full max-md:w-full">
              <div className="text-center mt-4 text-3xl font-medium text-zinc-800">
                Login to your account
              </div>
              <div className="text-center mt-1 text-base text-blue-400">
                <span className='underline'><a href='/signup'>Don't have an account?</a></span>{" "}
                <span className='underline'><a href='/signup'>Sign Up</a></span>
              </div>
              <div className="text-center mt-4 text-zinc-800">
                Or
              </div>
              <button
                onClick={handleGoogleLogin}
                className="flex items-center justify-center px-5 py-2 mt-4 text-2xl font-medium text-blue-900 bg-white border-2 rounded-[32px] max-md:px-5"
              >
                <img
                  src={google}
                  alt="Google logo"
                  className="w-6 h-6 mr-2"
                />
                Sign in with Google
              </button>
            </div>
            <div className="flex flex-col grow mt-10 max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col mt-11 text-base text-zinc-500 max-md:mt-10">
                <div className="flex flex-col pt-1.5 w-full">
                  <label>Email address</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="shrink-0 mt-3.5 h-14 rounded-xl border border-solid border-blue-500 border-opacity-30 p-2"
                  />
                </div>
                <div className="flex flex-col pt-1.5 w-full mt-6">
                  <label>Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="shrink-0 mt-3.5 h-14 rounded-xl border border-solid border-blue-500 border-opacity-30 p-2"
                  />
                </div>
                <div className="flex gap-2 self-start py-2 mt-2 text-base text-zinc-800">
                  <label>
                    <input 
                      type="checkbox" 
                      checked={showPassword}
                      onChange={() => setShowPassword(!showPassword)}
                    /> Show password
                  </label>
                </div>
              </div>
              <div className="flex gap-5 mt-10 max-md:flex-wrap max-md:max-w-full">
                <button
                  onClick={handleSubmit}
                  className="justify-center px-5 py-2 text-2xl font-medium text-center text-white bg-blue-900 rounded-[32px] max-md:px-5"
                >
                  Login
                </button>
              </div>
              {error && <p className="text-red-500 mt-4">{error}</p>}
              {success && <p className="text-green-500 mt-4">{success}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
