import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverUrl } from '../config';
import google from '../assets/google.png';

function Signup() {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email !== confirmEmail) {
      setError('Emails do not match');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
      return;
    }

    const response = await fetch(`${serverUrl}/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, firstname: firstName, lastname: lastName }),
    });

    const data = await response.json();

    if (response.ok) {
      setSuccess('Verification code sent to your email. Please enter the code to complete the signup.');
      setIsVerifying(true);
      setError('');
    } else {
      setError(data.error);
      setSuccess('');
    }
  };

  const handleVerify = async () => {
    const response = await fetch(`${serverUrl}/auth/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, code: verificationCode }),
    });

    const data = await response.json();

    if (response.ok) {
      setSuccess(data.message);
      setError('');
      setEmail('');
      setConfirmEmail('');
      setPassword('');
      setConfirmPassword('');
      setFirstName('');
      setLastName('');
      setVerificationCode('');
      navigate('/login');
    } else {
      setError(data.error);
      setSuccess('');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await fetch(`${serverUrl}/auth/login/google`, {
        method: 'GET',
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.authorization_url;
      } else {
        setError('Failed to initiate Google login.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="flex justify-center items-center bg-blue-50 max-md:px-5 min-h-screen pt-12">
      <div className="flex flex-col mt-5 w-full max-w-[900px] max-md:max-w-full">
        <div className="py-10 px-10 bg-white rounded-3xl border border-solid border-blue-500 border-opacity-50 max-md:px-5 max-md:max-w-full my-5">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-full max-md:w-full">
              <div className="flex flex-col items-center w-full max-md:w-full">
                <div className="text-center mt-4 text-3xl font-medium text-zinc-800">
                  {isVerifying ? 'Enter Verification Code' : 'Create an account'}
                </div>
                {!isVerifying && (
                  <>
                    <div className="text-center mt-1 text-base text-blue-400">
                      <span className="text-blue-800 underline"><a href='/login'>Already have an account?</a></span>{" "}
                      <span className="text-blue-800 underline"><a href='/login'>Log In</a></span>
                      <div className="text-center mt-1 text-zinc-800">
                Or
              </div>
                    </div>
                    <br />
                    <button
                      onClick={handleGoogleLogin}
                      className="flex items-center justify-center px-5 py-2 text-2xl font-medium text-center text-blue-900 bg-white border-2 rounded-[32px] max-md:px-5"
                    >
                      <img
                        src={google}
                        alt="Google logo"
                        className="w-6 h-6 mr-2"
                      />
                      Sign up with Google
                    </button>
                  </>
                )}
                {isVerifying && (
                  <div className="flex flex-col items-center mt-10 w-full max-md:w-full">
                    <div className="flex flex-col pt-2 w-full">
                      <label>Verification Code</label>
                      <input
                        type="text"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        className="shrink-0 mt-3.5 h-14 rounded-xl border border-solid border-stone-500 border-opacity-30 p-2"
                      />
                    </div>
                    <div className="flex gap-5 mt-10 max-md:flex-wrap max-md:max-w-full">
                      <button
                        onClick={handleVerify}
                        className="justify-center px-5 py-2 text-2xl font-medium text-center text-white bg-blue-900 rounded-[32px] max-md:px-5"
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {!isVerifying && (
                <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-4 mt-11 text-base text-stone-500 max-md:flex-wrap max-md:mt-10">
                    <div className="flex flex-col pt-1.5 w-full">
                      <label>First name</label>
                      <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="shrink-0 mt-3.5 h-14 rounded-xl border border-solid border-stone-500 border-opacity-30 p-2"
                      />
                    </div>
                    <div className="flex flex-col pt-2 w-full">
                      <label>Last name</label>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="shrink-0 mt-3.5 h-14 rounded-xl border border-solid border-stone-500 border-opacity-30 p-2"
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 mt-6 text-base text-stone-500 max-md:flex-wrap">
                    <div className="flex flex-col pt-1.5 w-full">
                      <label>Email address</label><br />
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="shrink-0 mt-3.5 h-14 rounded-xl border border-solid border-stone-500 border-opacity-30 p-2 max-md:max-w-full"
                      />
                    </div>
                    <div className="flex flex-col pt-1.5 w-full">
                      <label>Confirm Email address</label><br />
                      <input
                        type="email"
                        value={confirmEmail}
                        onChange={(e) => setConfirmEmail(e.target.value)}
                        className="shrink-0 mt-3.5 h-14 rounded-xl border border-solid border-stone-500 border-opacity-30 p-2 max-md:max-w-full"
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 mt-6 text-base text-stone-500 max-md:flex-wrap">
                    <div className="flex flex-col pt-1.5 whitespace-nowrap w-full">
                      <label>Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="shrink-0 mt-3.5 h-14 rounded-xl border border-solid border-stone-500 border-opacity-30 p-2"
                      />
                    </div>
                    <div className="flex flex-col pt-1.5 w-full">
                      <label>Confirm your password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="shrink-0 mt-2.5 h-14 rounded-xl border border-solid border-stone-500 border-opacity-30 p-2"
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-base text-stone-500 max-md:mr-1.5 max-md:max-w-full">
                    Use 8 or more characters with a mix of letters, numbers & symbols
                  </div>
                  <div className="flex gap-2 self-start py-2 mt-2 text-base text-zinc-800">
                    <label>
                      <input
                        type="checkbox"
                        checked={showPassword}
                        onChange={() => setShowPassword(!showPassword)}
                      /> Show password
                    </label>
                  </div>
                  <div className="flex gap-5 mt-10 max-md:flex-wrap max-md:max-w-full">
                    <button
                      onClick={handleSubmit}
                      className="justify-center px-5 py-2 text-2xl font-medium text-center text-white bg-blue-900 rounded-[32px] max-md:px-5"
                    >
                      Create an account
                    </button>
                  </div>
                </div>
              )}
              {error && <p className="text-red-500 mt-4">{error}</p>}
              {success && <p className="text-green-500 mt-4">{success}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
