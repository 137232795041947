import React from 'react';
import { motion } from 'framer-motion';
import pdfIcon from '../../assets/pdf.png';
import wordIcon from '../../assets/word.png';

const ResumeUpload = ({ resumeAvailable, handleSwitchChange, handleFileChange, fileName, handleDrop, handleDragOver, handleDragLeave }) => (
    <motion.div 
        initial="hidden"
        animate="visible"
        className={`bg-blue-100 p-6 rounded-lg shadow-lg w-full md:w-1/2 mb-6 md:mb-0`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
    >
        <h2 className="text-2xl font-semibold mb-4 text-blue-900">Upload your Resume</h2>
        <p className="text-sm text-gray-700 mb-4">File should be of format .pdf or .docx. Maximum size 12MB.</p>
        {resumeAvailable && (
            <div className='flex my-2'>
                <p className='text-gray-700'>Use uploaded resume</p> 
                <label className="relative inline-flex cursor-pointer items-center mx-2">
                    <input id="switch" type="checkbox" className="peer sr-only" onChange={handleSwitchChange} />
                    <div className="peer h-6 w-11 rounded-full bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-cyan-400 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
                </label>
            </div>
        )}
        <form className="relative bg-blue-50 rounded-lg p-6">
            <input type="file" id="file-upload" className="hidden" onChange={handleFileChange} accept=".doc,.docx,.pdf" />
            <label htmlFor="file-upload" className="flex items-center justify-center cursor-pointer">
                <img className="w-16 h-16 mb-2" src={pdfIcon} alt="pdf" />
                <img className="w-16 h-16" src={wordIcon} alt="word" />
            </label>
            <p className="text-sm text-gray-700 text-center">Drag and drop or click to upload</p>
        </form>
        {fileName && <h1 className="text-center text-sm text-gray-700 mt-2">File Uploaded: {fileName}</h1>}
    </motion.div>
);

export default ResumeUpload;
