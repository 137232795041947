import React from 'react';
import { motion } from 'framer-motion';
import txtIcon from '../../assets/txt.png';
import wordIcon from '../../assets/word.png';
import pdfIcon from '../../assets/pdf.png';

const JobDescriptionUpload = ({ isTextFileUploaded, handleTextFileChange, handleLinkClick, textFileName }) => (
    <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
        className={`animate-fade-in-up bg-blue-100 p-6 rounded-lg shadow-lg w-full md:w-1/2`}
    >
        <h2 className="text-2xl font-semibold mb-4 text-blue-900">Upload a Job Description</h2>
        <p className="text-sm text-gray-700 mb-4">File should be of format .txt. Maximum size 1MB.</p>
        <form className="relative bg-blue-50 rounded-lg p-6">
            <input type="file" id="textfile-upload" className="hidden" onChange={handleTextFileChange} accept=".txt,.doc,.docx,.pdf" />
            <label htmlFor="textfile-upload" className="flex items-center justify-center cursor-pointer space-x-4">
                <img className="w-16 h-16" src={txtIcon} alt="txt" />
                <img className="w-16 h-16" src={wordIcon} alt="word" />
                <img className="w-16 h-16" src={pdfIcon} alt="pdf" />
            </label>
            <p className="text-sm text-gray-700 text-center">Drag and drop or click to upload</p>
        </form>
        <div className="text-center text-sm text-gray-700 mt-2">
            <p className="text-base text-gray-700">To paste the job description,</p>
            <a className={`text-base text-cyan-500 hover:text-cyan-700 ml-1`} href="#" onClick={handleLinkClick}>Click Here</a>
        </div>
        {textFileName && <h1 className="text-center text-sm text-gray-700 mt-2">File Uploaded: <span className={`text-cyan-500`}>{textFileName}</span></h1>}
    </motion.div>
);

export default JobDescriptionUpload;
