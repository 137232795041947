import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { serverUrl } from '../../config';
import { CircularProgress } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const [userList, setUserList] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverUrl}/admin/dashboard`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const { data } = response;
        const chartData = [
          { name: 'Resume Feedback', value: data['Resume Feedback'], service: 'resume' },
          { name: 'Cover Letter Feedback', value: data['Cover Letter Feedback'], service: 'cover' },
          { name: 'Interview Questions', value: data['Interview Questions'], service: 'interview' },
        ];
        setData(chartData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleBarClick = async (data) => {
    const service = data.service;
    setSelectedService(service);
    try {
      const response = await axios.get(`${serverUrl}/admin/users/${service}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUserList(response.data);
    } catch (error) {
      console.error('Error fetching user list:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };

  const fetchActiveUsers = async () => {
    try {
      const response = await axios.get(`${serverUrl}/admin/activeUsers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const { total_users, active_users } = response.data;
      setTotalUsers(total_users);
      setActiveUsers(active_users);

      const activeUsersResponse = await axios.get(`${serverUrl}/admin/activeUsersList`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUserList(activeUsersResponse.data);
      setSelectedService('activeUsers');
    } catch (error) {
      console.error('Error fetching active users:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <div className="w-1/5 h-screen bg-gray-800 text-white flex flex-col p-4">
        <h2 className="text-xl font-bold mb-8">Admin Dashboard</h2>
        <button
          className="mb-4 p-2 bg-gray-700 hover:bg-gray-600"
          onClick={() => setSelectedService(null)}
        >
          Dashboard
        </button>
        <button
          className="mb-4 p-2 bg-gray-700 hover:bg-gray-600"
          onClick={fetchActiveUsers}
        >
          Active Users
        </button>
      </div>
      <div className="flex flex-col items-center flex-grow p-4 overflow-auto">
        <h1 className="text-4xl font-bold mb-8">Admin Dashboard</h1>
        {selectedService === null && (
          <div className="bg-white p-4 w-full max-w-5xl mb-8 shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Service Usage</h2>
            <BarChart
              width={1000}
              height={500}
              data={data}
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}
              onClick={(e) => handleBarClick(e.activePayload[0].payload)}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </div>
        )}
        {selectedService === 'activeUsers' && (
          <div className="w-full max-w-5xl">
            <h2 className="text-2xl font-semibold mb-4">Active Users</h2>
            <div className="bg-white p-4 mb-4 shadow-md rounded-lg">
              <p>Total Users: {totalUsers}</p>
              <p>Active Users: {activeUsers}</p>
            </div>
          </div>
        )}
        {(selectedService === 'allUsers' || selectedService === 'activeUsers' || ['resume', 'cover', 'interview'].includes(selectedService)) && userList.length > 0 && (
          <div className="w-full max-w-5xl overflow-x-auto">
            <h2 className="text-2xl font-semibold mb-4">{selectedService === 'allUsers' ? 'All Users' : (selectedService === 'activeUsers' ? 'Active Users' : `${selectedService.charAt(0).toUpperCase() + selectedService.slice(1)} Users`)}</h2>
            <div className="bg-white p-4 shadow-md rounded-lg">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b">Email</th>
                    <th className="py-2 px-4 border-b">TimeStamp</th>
                    <th className="py-2 px-4 border-b">Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {userList.map((user, index) => (
                    <tr key={index}>
                      <td className="py-2 px-4 border-b text-center">{user.email}</td>
                      <td className="py-2 px-4 border-b text-center">{user.timestamp}</td>
                      <td className="py-2 px-4 border-b text-center">{user.action}</td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
