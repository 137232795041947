import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const GoogleCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');

        if (token) {
            localStorage.setItem('token', token);
            navigate('/services');
        } else {
            console.error('Token not found');
            navigate('/');
        }
    }, [navigate]);

    return <div>Loading...</div>;
};

export default GoogleCallback;
