import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight, FaCheckCircle, FaMicrophone, FaChalkboardTeacher, FaUserTie } from 'react-icons/fa';
import { motion } from 'framer-motion';

const MockInterview = () => {
    const navigate = useNavigate();
    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 to-purple-200 text-gray-800">
            {/* Main Content Section */}
            <div className="container mx-auto py-16 px-4 md:py-24 lg:px-8">
                <motion.div 
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className=" rounded-xl px-8 py-12 md:px-16 md:py-20 lg:px-24 lg:py-28 relative overflow-hidden"
                >
                    <div className="absolute inset-0 opacity-20 pointer-events-none"></div>
                    <div className="relative z-10 max-w-4xl mx-auto text-center">
                        <motion.h1
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1.2 }}
                            className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-purple-500 mb-4"
                        >
                            Mock Interview
                        </motion.h1>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => navigate('/interviews')}
                            className="px-8 py-3 my-4 text-xl font-medium bg-white rounded-full text-slate-500 hover:bg-slate-100 transition duration-300"
                        >
                            Start Now <FaArrowRight className="inline-block ml-2" /> 
                        </motion.button>
                    </div>

                    <motion.h2
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.4 }}
                        className="text-3xl md:text-4xl font-bold text-violet-950 text-center mt-12"
                    >
                        Why are mock interviews important?
                    </motion.h2>

                    {/* Benefits List */}
                    <motion.ul
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.6 }}
                        className="text-center list-none mt-8 text-lg md:text-xl leading-relaxed text-gray-800 space-y-4"
                    >
                        <li className="flex items-start">
                            <FaCheckCircle className="text-purple-500 mt-1 mr-2"/>
                            <span><strong>Build Confidence:</strong> Practicing interviews helps build confidence for the real thing.</span>
                        </li>
                        <li className="flex items-start">
                            <FaCheckCircle className="text-purple-500 mt-1 mr-2"/>
                            <span><strong>Receive Feedback:</strong> Get constructive feedback to improve your responses.</span>
                        </li>
                        <li className="flex items-start">
                            <FaCheckCircle className="text-purple-500 mt-1 mr-2"/>
                            <span><strong>Reduce Anxiety:</strong> Familiarizing yourself with the interview process reduces anxiety.</span>
                        </li>
                        <li className="flex items-start">
                            <FaCheckCircle className="text-purple-500 mt-1 mr-2"/>
                            <span><strong>Improve Communication:</strong> Enhance your communication skills by practicing your answers.</span>
                        </li>
                        <li className="flex items-start">
                            <FaCheckCircle className="text-purple-500 mt-1 mr-2"/>
                            <span><strong>Identify Weaknesses:</strong> Spot areas of improvement before the actual interview.</span>
                        </li>
                    </motion.ul>
                </motion.div>
            </div>

            {/* How It Works Section */}
            <div className="bg-white py-16 px-4 md:py-24 lg:px-8">
                <div className="max-w-5xl mx-auto">
                    <motion.h2
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.8 }}
                        className="text-3xl md:text-4xl font-bold text-violet-950 text-center"
                    >
                        How does it work?
                    </motion.h2>

                    <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Step 1 */}
                        <motion.div
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.8 }}
                            className="text-center"
                        >
                            <div className="bg-purple-200 p-8 rounded-lg shadow-md h-full flex flex-col justify-center">
                                <FaMicrophone className="text-4xl text-purple-500 mb-4 mx-auto" />
                                <h3 className="text-xl font-semibold mb-2">Step 1</h3>
                                <p className="text-gray-700">Upload a description for job advertisment</p>
                            </div>
                        </motion.div>
                        
                        {/* Step 2 */}
                        <motion.div
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.9 }}
                            className="text-center"
                        >
                            <div className="bg-purple-200 p-8 rounded-lg shadow-md h-full flex flex-col justify-center">
                                <FaChalkboardTeacher className="text-4xl text-purple-500 mb-4 mx-auto" />
                                <h3 className="text-xl font-semibold mb-2">Step 2</h3>
                                <p className="text-gray-700">Participate in a mock interview with an AI agent</p>
                            </div>
                        </motion.div>
                        
                        {/* Step 3 */}
                        <motion.div
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1 }}
                            className="text-center"
                        >
                            <div className="bg-purple-200 p-8 rounded-lg shadow-md h-full flex flex-col justify-center">
                                <FaUserTie className="text-4xl text-purple-500 mb-4 mx-auto" />
                                <h3 className="text-xl font-semibold mb-2">Step 3</h3>
                                <p className="text-gray-700">Receive detailed feedback and actionable insights</p>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MockInterview;
