import { readTextFile } from '../../components/fileReader/txtFile';
import { readDocxFile } from '../../components/fileReader/docxFile';
import { readPdfFile } from '../../components/fileReader/pdfFile';
import { serverUrl } from '../../config';
const MAX_FILE_SIZE = 1 * 1024 * 1024;
export const fetchResumeAvailability = async (setResumeAvailable, token) => {
    try {
        const response = await fetch(`${serverUrl}/resumeAvailable`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/login';
            return;
        }

        const data = await response.json();
        setResumeAvailable(data.resumeAvailable);
    } catch (error) {
        console.error('Error:', error);
    }
};

export const handleFileUpload = async (navigate, fileContent, useUploadedResume, updateResume, token, setIsLoading, setResponseData) => {
    navigate('/loading');
    const fileInput = document.querySelector('input[type="file"]');

    if (fileInput.files.length === 0 && !useUploadedResume) {
        console.error('No file selected');
        return;
    }

    const formData = new FormData();
    if (fileInput.files.length > 0) {
        const file = fileInput.files[0];
        formData.append('file', file);
    }
    formData.append('text', fileContent);
    formData.append('useUploadedResume', useUploadedResume);
    formData.append('updateResume', updateResume);
    setIsLoading(true);

    try {
        const response = await fetch(`${serverUrl}/resumeFeedback`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        if (response.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/login';
            return;
        }

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        setResponseData(responseData);
        navigate('/resumeViewer', { state: responseData });
        console.log('Success:', responseData);
    } catch (error) {
        console.error('Error:', error);
        navigate('/error');
    } finally {
        setIsLoading(false);
    }
};


export const handleTextFileChange = async (event, setTextFileName, setFileContent, setIsTextFileUploaded) => {
    const file = event.target.files[0];
    if (file) {
        if (file.size > MAX_FILE_SIZE) {
            alert('File size exceeds the maximum limit of 1MB.');
            setTextFileName(''); // Clear the file name
            return;
        }
        try {
            let text = '';
            if (file.type === 'text/plain') {
                text = await readTextFile(file);
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                text = await readDocxFile(file);
            } else if (file.type === 'application/pdf') {
                text = await readPdfFile(file);
            } else {
                throw new Error('Unsupported file type');
            }
            setTextFileName(file.name);
            setFileContent(text);
            setIsTextFileUploaded(true);
        } catch (error) {
            console.error('Error reading file:', error);
        }
    }
};
