import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { readTextFile } from '../components/fileReader/txtFile';
import { readDocxFile } from '../components/fileReader/docxFile';
import { readPdfFile } from '../components/fileReader/pdfFile';
import '../styles/index.css';
import { serverUrl } from '../config';


function CoverEditor() {
  const [responseData, setResponseData] = useState(null);
  const [textInput, setTextInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [fileName, setFileName] = useState(''); // State to store file name
  const [textFileName, setTextFileName] = useState(''); // State to store text file name
  const [fileContent, setFileContent] = useState('');
  const [isTextFileUploaded, setIsTextFileUploaded] = useState(false);
  const [coverAvailable, setCoverAvailable] = useState(false);
  const [useUploadedCoverLetter, setUseUploadedCoverLetter] = useState(false);
  const [updateCover, setUpdateCover] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State to manage the disabled state of submit button
  const [isDragging, setIsDragging] = useState(false); // State to manage dragging
  const [isJobDescriptionConfirmed, setIsJobDescriptionConfirmed] = useState(false); // State for confirming job description
  const token = localStorage.getItem('token');
  const MAX_FILE_SIZE = 1 * 1024 * 1024;
  useEffect(() => {
    const fetchCoverAvailable = async () => {
        try {
            const response = await fetch(`${serverUrl}/coverAvailable`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 401 || response.status === 422) {
                localStorage.removeItem('token');
                navigate('/login');
                return;
            }

            const data = await response.json();
            setCoverAvailable(data.coverAvailable);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    fetchCoverAvailable();
}, [token, setCoverAvailable]);

  useEffect(() => {
    // Update the disabled state of the submit button based on conditions
    setIsSubmitDisabled(!(fileName || useUploadedCoverLetter) || fileContent.trim() === '');
  }, [fileName, useUploadedCoverLetter, fileContent]);

  const handleDragOver = (e) => {
    e.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    handleFileChange({ target: { files } });
  };

  const handleTextFileDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    handleTextFileChange({ target: { files } });
  };

  function handleFileUpload(event) {
    navigate('/loading');
    const fileInput = document.querySelector('input[type="file"]');

    if (fileInput.files.length === 0 && !useUploadedCoverLetter) {
        console.error('No file selected');
        return;
    }

    const formData = new FormData();
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      formData.append('file', file);
    }
    formData.append('text', fileContent);  // Use fileContent for job description
    formData.append('useUploadedCoverLetter', useUploadedCoverLetter); // Append the boolean
    formData.append('updateCover', updateCover); // Append the boolean
    setIsLoading(true);

    fetch(`${serverUrl}/coverFeedback`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(responseData => {
        setResponseData(responseData);
        navigate('/coverLetterViewer', { state: responseData }); // Redirect to  
        console.log('Success:', responseData);
    })
    .catch(error => {
        console.error('Error:', error);
        navigate('/error');
    })
    .finally(() => {
        setIsLoading(false);
    });
  }

  const handleLinkClick = (event) => {
    event.preventDefault();
    setIsTextFileUploaded(true);
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        alert('File size exceeds the maximum limit of 1MB.');
        setFileName(''); // Clear the file name
      } else {
        setFileName(file.name);
      }
    } else {
      setFileName('');
    }
  };

  const handleSwitchChange = () => {
    setUseUploadedCoverLetter(prevState => !prevState);
  };

  const handleTextFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert('File size exceeds the maximum limit of 1MB.');
        setTextFileName(''); // Clear the file name
        return;
      }
      try {
        let text = '';
        if (file.type === 'text/plain') {
          text = await readTextFile(file);
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          text = await readDocxFile(file);
        } else if (file.type === 'application/pdf') {
          text = await readPdfFile(file);
        } else {
          throw new Error('Unsupported file type');
        }
        setTextFileName(file.name);
        setFileContent(text);
        setIsTextFileUploaded(true);
      } catch (error) {
        console.error('Error reading file:', error);
      }
    }
  };  

  const handleBackToFileUpload = () => {
    setIsTextFileUploaded(false);
  };

  const handleUpdateCover = () => {
    setUpdateCover(prevState => !prevState);
    console.log('Update Cover:', updateCover);
  };

  const handleClear = () => {
    setFileContent('');
  };

  const handleConfirm = () => {
    setIsJobDescriptionConfirmed(true);
  };

  const coverVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-rose-50 to-rose-200 text-gray-800 flex flex-col py-10">
      <div className='animate-fade-in-up container mx-auto flex-1 p-6 flex flex-col'>
        <motion.div 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center py-8"
        >
          <motion.h1 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.2 }}
            className={`text-5xl font-extrabold leading-10 tracking-tight text-rose-600 md:text-3xl lg:text-5xl`}
          >
            Cover Letter Wizard
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.4 }}
            className="mt-4 text-lg font-light text-gray-700"
          >
            Upload a job description and your cover letter. We will modify it according to the job requirements.
          </motion.p>
        </motion.div>
        <section className="bg-white py-12 rounded-lg shadow-lg">
          <div className="max-w-4xl mx-auto flex flex-col md:flex-row md:space-x-5 items-center items-stretch justify-center">
            { !isTextFileUploaded &&
              <motion.div 
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8 }}
                className={`animate-fade-in-up bg-rose-100 p-6 rounded-lg shadow-lg w-full md:w-1/2`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleTextFileDrop}
              >
                <h2 className="text-2xl font-semibold mb-4 text-rose-900">Upload a Job Description</h2>
                <p className="text-sm text-gray-700 mb-4">File should be of format .txt. Maximum size 12MB.</p>
                <br></br><div className='my-2'></div>
                <form className="relative bg-rose-50 rounded-lg p-6">
                  <input type="file" id="textfile-upload" className="hidden" onChange={handleTextFileChange} accept=".txt,.doc,.docx,.pdf" ></input>
                  <label htmlFor="textfile-upload" className="flex items-center justify-center cursor-pointer space-x-4">
                    <img className="w-16 h-16" src={require('../assets/txt.png')} alt="txt" />
                    <img className="w-16 h-16" src={require('../assets/word.png')} alt="word" />
                    <img className="w-16 h-16" src={require('../assets/pdf.png')} alt="pdf" />
                  </label>
                  <p className="text-sm text-gray-700 text-center">Drag and drop or click to upload</p>
                </form>
                <div className="text-center text-sm text-gray-700 mt-2">
                  <p className="text-base text-gray-700">To paste the job description,</p>
                  <a className={`text-base text-rose-600 hover:text-rose-700 ml-1`} href="#" onClick={handleLinkClick}>Click Here</a>
                </div>
                {textFileName && <h1 className="text-center text-sm text-gray-700 mt-2">File Uploaded: <span className={`text-rose-600`}>{textFileName}</span></h1>}
              </motion.div>
            }
            { isTextFileUploaded &&
              <motion.div 
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8 }}
                className="animate-fade-in-up bg-rose-100 p-6 rounded-lg shadow-lg w-full md:w-1/2 relative"
              >
                <textarea
                  readOnly={isJobDescriptionConfirmed}
                  id="message"
                  name="message"
                  className="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-rose-400 resize-none text-black"
                  placeholder="Enter Job description here"
                  rows="10"
                  value={fileContent}
                  onChange={(e) => setFileContent(e.target.value)}
                />
                {!isJobDescriptionConfirmed &&
                <button onClick={handleBackToFileUpload} className="absolute top-0 right-0 m-2 bg-rose-500 text-white p-2 rounded-full">X</button>
                }
                {!isJobDescriptionConfirmed &&
                <div className="flex justify-end space-x-2 mt-2">
                  <button onClick={handleClear} className="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded">Clear</button>
                  <button onClick={handleConfirm} className="bg-rose-500 hover:bg-rose-400 text-white font-bold py-2 px-4 rounded">Confirm</button>
                </div>
            }
              </motion.div>
            }
            {isJobDescriptionConfirmed && 
            <motion.div 
              initial="hidden"
              animate="visible"
              variants={coverVariants}
              className={`bg-rose-100 p-6 rounded-lg shadow-lg w-full md:w-1/2 mb-6 md:mb-0`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <h2 className="text-2xl font-semibold mb-4 text-rose-900">Upload your Cover Letter</h2>
              <p className="text-sm text-gray-700 mb-4">File should be of format .pdf or .docx. Maximum size 12MB.</p>
              {coverAvailable &&
              <div className='flex my-2'>
              <p className='text-gray-700'>Use uploaded cover letter</p> 
              <label className="relative inline-flex cursor-pointer items-center mx-2">
                <input id="switch" type="checkbox" className="peer sr-only" onChange={handleSwitchChange}/>
                <label htmlFor="switch" className="hidden"></label>
                <div className="peer h-6 w-11 rounded-full bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-rose-400 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-rose-300"></div>
              </label>
              </div>
              }
              <form className="relative bg-rose-50 rounded-lg p-6">
                <input type="file" id="file-upload" className="hidden" onChange={handleFileChange} accept=".doc,.docx,.pdf"></input>
                <label htmlFor="file-upload" className="flex items-center justify-center cursor-pointer">
                  <img className="w-16 h-16 mb-2" src={require('../assets/pdf.png')} alt="pdf"></img>
                  <img className="w-16 h-16" src={require('../assets/word.png')} alt="word"></img>
                </label>
                <p className="text-sm text-gray-700 text-center">Drag and drop or click to upload</p>
              </form>
              {fileName && <h1 className="text-center text-sm text-gray-700 mt-2">File Uploaded: {fileName}</h1>}
            </motion.div>
            }
          </div>
          {fileName && (
            <motion.div 
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8 }}
              className="animate-fade-in-up max-w-4xl mx-auto flex flex-col md:flex-row items-center justify-center my-5 space-y-4 md:space-y-0 md:space-x-5 bg-rose-200 p-4 rounded-lg shadow-lg"
            >
              <div className="flex flex-col md:flex-row items-center bg-rose-100 p-4 rounded-lg shadow-inner w-full md:w-auto">
                <p className="text-gray-700 mb-2 md:mb-0">You uploaded a new file. Would you like to save it for easier access in the future?</p>
                <label className="relative inline-flex cursor-pointer items-center mx-2 mt-2 md:mt-0">
                  <input id="switch" type="checkbox" className="peer sr-only" onChange={handleUpdateCover}/>
                  <div className="peer h-6 w-11 rounded-full bg-gray-500 after:absolute after:left-1 after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-rose-500 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-rose-300"></div>
                </label>
              </div>
            </motion.div>
          )}

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="flex justify-center py-10"
          >
            <button 
              onClick={(event) => handleFileUpload(event)}
              className={`shadow bg-rose-500 hover:bg-rose-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded ${isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              type="submit"
              disabled={isSubmitDisabled} // Disable the button if conditions are not met
            >
              Suggest Changes
            </button>
          </motion.div>
        </section>
      </div>
    </div>
  );
}

export default CoverEditor;
