import React from 'react';
import theme from '../../styles/theme.js';
import { Link } from 'react-router-dom'; // Import Link for navigation

const ErrorPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-200 to-sky-50 flex flex-col items-center justify-center text-black">
            <div className="container mx-auto text-center py-24 px-8 rounded-lg shadow-lg bg-white">
                <div className="mb-8 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg> 
                </div>
                <h1 className={`text-5xl md:text-6xl font-extrabold leading-tight ${theme.cyanTextColor} mb-4`}>
                    Oops! 
                </h1>
                <p className="text-2xl md:text-3xl text-gray-700 mb-8">
                    That wasn't supposed to happen.
                </p>
                <p className="text-lg md:text-xl text-gray-600 mb-12">
                    The page you are looking for doesn't exist or an error occurred. <br />
                    If you think this is a mistake, please let us know.
                </p>
                <div className="space-x-4">
                    <Link 
                        to="/" 
                        className={`bg-${theme.cyanTextColor} text-black hover:bg-cyan-300 font-semibold py-3 px-8 rounded-lg transition-colors duration-300`}
                    >
                        Go Back Home
                    </Link>
                    <a 
                        href="https://docs.google.com/forms/d/e/1FAIpQLSc9xE9MMbAhRnQ7L3LkzOLpDuygyr3kq-9Zm6FdsakLvWD1yA/viewform" 
                        className="bg-gray-300 text-black hover:bg-gray-400 font-semibold py-3 px-8 rounded-lg transition-colors duration-300"
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Give Feedback
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
