import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; // Import the FaCheckCircle component
import { motion } from 'framer-motion';
import theme from '../../styles/theme.js';
import image from '../../assets/revidewdesklogonew.png';

const Home = () => {
    const token = localStorage.getItem('token');
    let value = '/login';
    if (token) value = '/services';

    return (
        <div className="bg-white text-white">
            <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-200 text-gray-800">
                <div className="container mx-auto py-20 px-4 md:py-32 lg:px-8">
                    <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-10 items-center">
                        {/* Text Content */}
                        <motion.div 
                            initial={{ opacity: 0, x: -50 }} 
                            animate={{ opacity: 1, x: 0 }} 
                            transition={{ duration: 1 }}
                            className="text-center md:text-left"
                        >
                            <h2 className="text-4xl md:text-5xl lg:text-5xl font-extrabold mb-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                                AI-Powered Assistance
                            </h2>
                            <h2 className="text-4xl md:text-5xl lg:text-5xl font-extrabold mb-8 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                                for Job Seekers
                            </h2>
                            <p className="text-lg md:text-xl leading-relaxed mb-10">
                                Boost your confidence and improve your interview skills with our mock interviewing service. <br />
                                Transform and optimize your resume and cover letter for specific job postings.
                            </p>
                            <a
                                href={value}
                                className={`inline-block px-8 py-3 text-lg font-semibold rounded-full bg-blue-600 hover:bg-blue-700 text-white transition duration-300`}
                            >
                                Get Started
                            </a>
                        </motion.div>

                        {/* Image */}
                        <motion.div 
                            initial={{ opacity: 0, scale: 0.8 }} 
                            animate={{ opacity: 1, scale: 1 }} 
                            transition={{ duration: 1 }}
                            className="flex justify-center items-center"
                        >
                            <img src={image} alt="Job Seeker Illustration" className="w-3/4 md:w-full rounded-lg" />
                        </motion.div>
                    </div>

                    {/* Feature Highlights */}
                    <div className="mt-20 grid md:grid-cols-3 gap-8">
                        <motion.div 
                            initial={{ opacity: 0, y: 50 }} 
                            animate={{ opacity: 1, y: 0 }} 
                            transition={{ duration: 1 }}
                            className="p-6 bg-white rounded-lg shadow-lg"
                        >
                            <FaCheckCircle className={`text-2xl mb-2 ${theme.cyanTextColor}`} />
                            <h3 className="text-xl font-semibold mb-2">Resume Feedback</h3>
                            <p className="text-gray-600">Get detailed feedback to make your resume stand out.</p>
                        </motion.div>
                        <motion.div 
                            initial={{ opacity: 0, y: 50 }} 
                            animate={{ opacity: 1, y: 0 }} 
                            transition={{ duration: 1, delay: 0.2 }}
                            className="p-6 bg-white rounded-lg shadow-lg"
                        >
                            <FaCheckCircle className={`text-2xl mb-2 ${theme.cyanTextColor}`} />
                            <h3 className="text-xl font-semibold mb-2">Cover Letter Feedback</h3>
                            <p className="text-gray-600">Receive personalized suggestions to enhance your cover letter.</p>
                        </motion.div>
                        <motion.div 
                            initial={{ opacity: 0, y: 50 }} 
                            animate={{ opacity: 1, y: 0 }} 
                            transition={{ duration: 1, delay: 0.4 }}
                            className="p-6 bg-white rounded-lg shadow-lg"
                        >
                            <FaCheckCircle className={`text-2xl mb-2 ${theme.cyanTextColor}`} />
                            <h3 className="text-xl font-semibold mb-2">Mock Interviews</h3>
                            <p className="text-gray-600">Practice with AI-driven mock interviews to prepare for the real thing.</p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
