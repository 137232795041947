import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import theme from '../styles/theme.js';
import '../styles/index.css';
import JobDescriptionUpload from '../components/resume/JobDescriptionUpload.js';
import ResumeUpload from '../components/resume/ResumeUpload.js';
import { fetchResumeAvailability, handleFileUpload, handleTextFileChange } from '../components/resume/helpers.js';

function ResumeEditor() {
    const [responseData, setResponseData] = useState(null);
    const [textInput, setTextInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [fileName, setFileName] = useState('');
    const [textFileName, setTextFileName] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [isTextFileUploaded, setIsTextFileUploaded] = useState(false);
    const [resumeAvailable, setResumeAvailable] = useState(false);
    const [useUploadedResume, setUseUploadedResume] = useState(false);
    const [updateResume, setUpdateResume] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const [isJobDescriptionConfirmed, setIsJobDescriptionConfirmed] = useState(false);
    const token = localStorage.getItem('token');
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes

    useEffect(() => {
        fetchResumeAvailability(setResumeAvailable, token);
    }, [token]);

    useEffect(() => {
        setIsSubmitDisabled(!(fileName || useUploadedResume) || fileContent.trim() === '');
    }, [fileName, useUploadedResume, fileContent]);

    const handleDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        if (isDragging) setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        handleFileChange({ target: { files } });
    };

    const handleTextFileDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        handleTextFileChange({ target: { files } }, setTextFileName, setFileContent, setIsTextFileUploaded);
    };

    const handleLinkClick = (event) => {
        event.preventDefault();
        setIsTextFileUploaded(true);
    };

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.size > MAX_FILE_SIZE) {
                alert('File size exceeds the maximum limit of 1MB.');
                setFileName(''); // Clear the file name
            } else {
                setFileName(file.name);
            }
        } else {
            setFileName('');
        }
    };    

    const handleSwitchChange = () => {
        setUseUploadedResume(prevState => !prevState);
    };

    const handleBackToFileUpload = () => {
        setIsTextFileUploaded(false);
    };

    const handleUpdateResume = () => {
        setUpdateResume(prevState => !prevState);
        console.log('Update Resume:', updateResume);
    };

    const handleClear = () => {
        setFileContent('');
    };

    const handleConfirm = () => {
        setIsJobDescriptionConfirmed(true);
    };

    const resumeVariants = {
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-200 text-gray-800 flex flex-col py-10">
            <div className='animate-fade-in-up container mx-auto flex-1 p-6 flex flex-col'>
                <motion.div 
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-center py-8"
                >
                    <motion.h1 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.2 }}
                        className={`text-5xl font-extrabold leading-10 tracking-tight ${theme.cyanTextColor} md:text-3xl lg:text-5xl`}
                    >
                        Resume Wizard
                    </motion.h1>
                    <motion.p 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.4 }}
                        className="mt-4 text-lg font-light text-gray-700"
                    >
                        Upload a job description and your resume. We will modify the resume according to the job requirements.
                    </motion.p>
                </motion.div>
                <section className="bg-white py-12 rounded-lg shadow-lg">
                    <div className="max-w-4xl mx-auto flex flex-col md:flex-row md:space-x-5 items-center items-stretch justify-center">
                        {!isTextFileUploaded && (
                            <JobDescriptionUpload
                                isTextFileUploaded={isTextFileUploaded}
                                handleTextFileChange={(e) => handleTextFileChange(e, setTextFileName, setFileContent, setIsTextFileUploaded)}
                                handleLinkClick={handleLinkClick}
                                textFileName={textFileName}
                            />
                        )}
                        {isTextFileUploaded && (
                            <motion.div 
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.8 }}
                                className="animate-fade-in-up bg-blue-100 p-6 rounded-lg shadow-lg w-full md:w-1/2 relative"
                            >
                                <textarea
                                    readOnly={isJobDescriptionConfirmed}
                                    id="message"
                                    name="message"
                                    className="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-cyan-400 resize-none text-black"
                                    placeholder="Enter Job description here"
                                    rows="10"
                                    value={fileContent}
                                    onChange={(e) => setFileContent(e.target.value)}
                                />
                                {!isJobDescriptionConfirmed && (
                                    <>
                                        <button onClick={handleBackToFileUpload} className="absolute top-0 right-0 m-2 bg-cyan-500 text-white p-2 rounded-full">X</button>
                                        <div className="flex justify-end space-x-2 mt-2">
                                            <button onClick={handleClear} className="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded">Clear</button>
                                            <button onClick={handleConfirm} className="bg-cyan-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 rounded">Confirm</button>
                                        </div>
                                    </>
                                )}
                            </motion.div>
                        )}
                        {isJobDescriptionConfirmed && (
                            <ResumeUpload
                                resumeAvailable={resumeAvailable}
                                handleSwitchChange={handleSwitchChange}
                                handleFileChange={handleFileChange}
                                fileName={fileName}
                                handleDrop={handleDrop}
                                handleDragOver={handleDragOver}
                                handleDragLeave={handleDragLeave}
                            />
                        )}
                    </div>
                    {fileName && (
                        <motion.div 
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.8 }}
                            className="animate-fade-in-up max-w-4xl mx-auto flex flex-col md:flex-row items-center justify-center my-5 space-y-4 md:space-y-0 md:space-x-5 bg-blue-200 p-4 rounded-lg shadow-lg"
                        >
                            <div className="flex flex-col md:flex-row items-center bg-blue-100 p-4 rounded-lg shadow-inner w-full md:w-auto">
                                <p className="text-gray-700 mb-2 md:mb-0">You uploaded a new file. Would you like to save it for easier access in the future?</p>
                                <label className="relative inline-flex cursor-pointer items-center mx-2 mt-2 md:mt-0">
                                    <input id="switch" type="checkbox" className="peer sr-only" onChange={handleUpdateResume} />
                                    <div className="peer h-6 w-11 rounded-full bg-gray-500 after:absolute after:left-1 after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-cyan-500 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-cyan-300"></div>
                                </label>
                            </div>
                        </motion.div>
                    )}
                    <motion.div 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        className="flex justify-center py-10"
                    >
                        <button 
                            onClick={() => handleFileUpload(navigate, fileContent, useUploadedResume, updateResume, token, setIsLoading, setResponseData)}
                            className={`shadow ${theme.cyanColor} hover:${theme.cyanColorHover} focus:shadow-outline focus:outline-none text-black font-bold py-2 px-6 rounded ${isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                            type="submit"
                            disabled={isSubmitDisabled}
                        >
                            Suggest Changes
                        </button>
                    </motion.div>
                </section>
            </div>
        </div>
    );
}

export default ResumeEditor;
