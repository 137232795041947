import React, { useState } from 'react';

const QuestionMarkTooltip = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative flex items-center justify-center bg-white min-h-screen">
      <div
        className="relative text-xl font-bold cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        ?
        {isHovered && (
          <div className="absolute top-0 left-full ml-2 py-2 px-4 bg-gray-800 text-white text-sm rounded-lg shadow-lg w-64">
            This is basic mic feature for you. In real interview you would not be typing your response. 
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionMarkTooltip;
