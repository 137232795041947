import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { MoonLoader } from 'react-spinners';  // Import the spinner

const LoadingPage = () => {
    const tasks = [
        'Initializing...',
        'Fetching data...',
        'Processing...',
        'Applying changes...',
        'Finalizing...'
    ];

    const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTaskIndex((prevIndex) => {
                if (prevIndex < tasks.length - 1) {
                    return prevIndex + 1;
                } else {
                    clearInterval(interval);
                    return prevIndex;
                }
            });
        }, 2000);

        return () => clearInterval(interval);
    }, [tasks.length]);

    useEffect(() => {
        setProgress(((currentTaskIndex + 1) / tasks.length) * 100);
    }, [currentTaskIndex, tasks.length]);

    return (
        <div className="bg-gradient-to-br from-blue-50 to-blue-200 min-h-screen flex flex-col items-center justify-center text-white">
            <MoonLoader color="#00BFFF" size={50} />
            <div className="text-center mb-10">

                {/* <h1 className="text-3xl font-extrabold text-cyan-400">Loading...</h1> */}
                <p className="mt-2 text-lg font-light text-gray-800">Please wait while we process your request.</p>
            </div>
            <div className="w-full max-w-4xl">
                <div className="flex justify-between items-center mb-8">
                    {tasks.map((task, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: index <= currentTaskIndex ? 1 : 0.5 }}
                            transition={{ duration: 1 }}
                            className="flex-1 px-2"
                        >
                            <div className={`p-4 rounded shadow-lg text-sm text-center ${index <= currentTaskIndex ? 'bg-cyan-400 text-black' : 'bg-gray-800 text-gray-400'}`}>
                                {task}
                            </div>
                        </motion.div>
                    ))}
                </div>
                <div className="relative w-full h-4 bg-gray-600 rounded-full overflow-hidden mb-8">
                    <motion.div
                        className="absolute inset-0"
                        initial={{ width: 0 }}
                        animate={{ width: `${progress}%` }}
                        transition={{ duration: 2 }}
                    >
                        <div className="h-full bg-cyan-400"></div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPage;
