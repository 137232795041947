import React, { useState, useEffect, useRef } from 'react';
import { readTextFile } from '../components/fileReader/txtFile';
import { readDocxFile } from '../components/fileReader/docxFile';
import { readPdfFile } from '../components/fileReader/pdfFile';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../styles/interview.css';
import { serverUrl } from '../config';
import micOn from '../assets/interview/microphoneon.png';
import micOff from '../assets/interview/microphoneoff.png';

function Interviews() {
    const [textFileName, setTextFileName] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [isTextFileUploaded, setIsTextFileUploaded] = useState(false);
    const [interview, setInterview] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [responses, setResponses] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
    const scrollableDivRef = useRef(null);
    const [transcript, setTranscript] = useState('');
    const [listening, setListening] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isSupported, setIsSupported] = useState(true);
    const recognitionRef = useRef(null);
    const [isListening, setIsListening] = useState(false);
    useEffect(() => {
        // Scroll to the bottom whenever responses change
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
    }, [responses, questions]);
    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            recognitionRef.current = new SpeechRecognition();
    
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;
    
            // State to store the final transcript
            let finalTranscript = '';
    
            recognitionRef.current.onresult = (event) => {
                let interimTranscript = '';
    
                for (let i = event.resultIndex; i < event.results.length; i++) {
                    const transcript = event.results[i][0].transcript;
    
                    if (event.results[i].isFinal) {
                        finalTranscript += transcript;
                        setTranscript(finalTranscript);
                    } else {
                        interimTranscript += transcript;
                    }
                }
    
                // Update the transcript with the combination of final and interim results
                setTranscript(finalTranscript + interimTranscript);
            };
        }
    }, []);

    const handleSend = async () => {
        const message = transcript.trim();

        if (message !== '') {
            const updatedResponses = [...responses, { question: questions[currentQuestionIndex], response: message }];
            setResponses(updatedResponses);
            setTranscript('');

            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex(prevIndex => prevIndex + 1);
            } else if (updatedResponses.length < 8) {
                await getQuestion(updatedResponses);
                setCurrentQuestionIndex(prevIndex => prevIndex + 1);
            } else {
                setAllQuestionsAnswered(true);
                await new Promise(resolve => setTimeout(resolve, 0));
                submitResponses(updatedResponses);
            }
        }
    };

    const handleStartListening = () => {
        if (recognitionRef.current) {
            recognitionRef.current.start();
            setIsListening(true);
        }
    };

    const handleStopListening = () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            setIsListening(false);
        }
    };

    const getQuestion = async (responsesSoFar = []) => {
        try {
            setLoading(true);
            const response = await fetch(`${serverUrl}/getQuestion`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    job_description: fileContent,
                    responses: responsesSoFar
                })
            });
    
            if (response.status === 401 || response.status === 422) {
                localStorage.removeItem('token');
                window.location.href = '/login';
                return;
            }
    
            const data = await response.json();
            if (data && data.question) {
                const newQuestion = data.question;
                setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
                setInterview(true);
            } else {
                console.error('Unexpected response structure:', data);
            }
        } catch (error) {
            console.error('Error fetching question:', error);
            navigate('/error');
        } finally {
            setLoading(false);
        }
    };

    const submitResponses = async (updatedResponses) => {
        try {
            navigate('/loading');
            const response = await fetch(`${serverUrl}/getScore`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ responses: updatedResponses })
            });
    
            if (response.status === 401) {
                localStorage.removeItem('token');
                window.location.href = '/login';
                return;
            }
    
            const feedback = await response.json();
            navigate('/feedback', { state: feedback });
        } catch (error) {
            console.error('Error submitting responses:', error);
            navigate('/error');
        }
    };
    
    const handleBackToFileUpload = () => {
        setIsTextFileUploaded(false);
      };
    const handleTextFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                let text = '';
                if (file.type === 'text/plain') {
                    text = await readTextFile(file);
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    text = await readDocxFile(file);
                } else if (file.type === 'application/pdf') {
                    text = await readPdfFile(file);
                } else {
                    throw new Error('Unsupported file type');
                }
                setTextFileName(file.name);
                setFileContent(text);
                setIsTextFileUploaded(true);
            } catch (error) {
                console.error('Error reading file:', error);
            }
        }
    };

    const handleLinkClick = (event) => {
        event.preventDefault();
        setIsTextFileUploaded(true);
    };

    const startInterview = async () => {
        setQuestions([]);
        setResponses([]);
        setCurrentQuestionIndex(0);
        await getQuestion([]);
    };

    const handleClear = () => {
        setFileContent('');
    };

    const interviewVariants = {
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
    };

    if (!isSupported) {
        return <p>Your browser does not support speech recognition. Please try using Chrome or Edge.</p>;
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 to-purple-200 text-gray-800 flex flex-col py-10">
            <div className="animate-fade-in-up container mx-auto flex-1 p-6 flex flex-col">
                <motion.div 
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-center py-8"
                >
                    <motion.h1 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.2 }}
                        className="text-5xl font-extrabold leading-10 tracking-tight text-purple-600 md:text-3xl lg:text-5xl"
                    >
                        Interview Preparation
                    </motion.h1>
                    <motion.p 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.4 }}
                        className="mt-4 text-lg font-light text-gray-700"
                    >
                        Let's help you get that job.
                    </motion.p>
                </motion.div>
                {!interview && (
                    <div className="animate-fade-in-up flex flex-col items-center justify-center py-12 space-y-6">
                        {!isTextFileUploaded && (
                            <motion.div 
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.8 }}
                                className="bg-purple-100 p-6 rounded-lg shadow-lg w-full max-w-md"
                            >
                                <h2 className="text-2xl font-semibold mb-4 text-purple-900">Upload a Job Description</h2>
                                <p className="text-sm text-gray-700 mb-4">File should be of format .txt, .docx, or .pdf. Maximum size 1 MB.</p>
                                <form className="relative bg-purple-50 rounded-lg p-6">
                                    <input 
                                        type="file" 
                                        id="textfile-upload" 
                                        className="hidden" 
                                        onChange={handleTextFileChange} 
                                        accept=".txt,.doc,.docx,.pdf"
                                    />
                                    <label htmlFor="textfile-upload" className="flex items-center justify-center cursor-pointer space-x-4">
                                        <img className="w-16 h-16" src={require('../assets/txt.png')} alt="txt" />
                                        <img className="w-16 h-16" src={require('../assets/word.png')} alt="word" />
                                        <img className="w-16 h-16" src={require('../assets/pdf.png')} alt="pdf" />
                                    </label>
                                </form>
                                <div className="text-center text-sm text-gray-700 mt-2">
                                    <p className="text-base text-gray-700">To paste the job description,</p>
                                    <a className="text-base text-purple-600 hover:text-purple-700 ml-1" href="#" onClick={handleLinkClick}>Click Here</a>
                                </div>
                                {textFileName && <h1 className="text-center text-sm text-gray-700 mt-2">File Uploaded: <span className="text-purple-600">{textFileName}</span></h1>}
                            </motion.div>
                        )}
                        {isTextFileUploaded && (
                            <motion.div 
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.8 }}
                                className="bg-purple-100 p-6 rounded-lg shadow-lg w-full max-w-md relative"
                            >
                                <textarea
                                    id="message"
                                    name="message"
                                    className="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-purple-400 resize-none text-black"
                                    placeholder="Enter Job description here"
                                    rows="10"
                                    value={fileContent}
                                    onChange={(e) => setFileContent(e.target.value)}
                                />
                                <button onClick={handleBackToFileUpload} className="absolute top-0 right-0 m-2 bg-purple-500 text-white p-2 rounded-full">X</button>
                                <div className="flex justify-end space-x-2 mt-2">
                                    <button onClick={handleClear} className="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded">Clear</button>
                                </div>
                                
                            </motion.div>
                        )}
                    </div>
                )}
                {!interview && (
                    <div className="flex justify-center">
                        {!loading && (
                            <button
                                onClick={startInterview}
                                disabled={!fileContent.trim()} // Disable button if no text in the textarea
                                className={`shadow bg-purple-500 hover:bg-purple-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded ${!fileContent.trim() ? 'opacity-50 cursor-not-allowed' : ''}`}
                                type="submit">
                                Start the Interview
                            </button>
                        )}
                        {loading && (
                            <button type="button" className="flex shadow bg-purple-500 hover:bg-purple-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded" disabled>
                                <svg aria-hidden="true" className="w-4 h-4 m-1 text-gray-200 animate-spin fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                Processing...
                            </button>
                        )}
                    </div>
                )}
                {interview && (
                    <div className="interview-container flex flex-col">
                        <div 
                        ref={scrollableDivRef}
                        className="flex-1 overflow-y-auto p-4 bg-white rounded-lg shadow-lg">
                            {responses.map((response, index) => (
                                <div key={index} className="px-4 py-2">
                                    <div className="flex items-center mb-2">
                                        <img className="w-8 h-8 rounded-full mr-2" src="https://picsum.photos/50/50" alt="User Avatar" />
                                        <div className="font-medium text-black">Interviewer</div>
                                    </div>
                                    <div className="bg-purple-700 rounded-lg p-4 shadow mb-2 max-w-screen-md text-white">
                                        <div>{response.question}</div>
                                    </div>
                                    <div className="flex items-center justify-end mb-2">
                                        <div className="bg-purple-400 text-black rounded-lg p-4 shadow mr-2 max-w-screen-md">
                                            {response.response}
                                        </div>
                                        <img className="w-8 h-8 rounded-full" src="https://picsum.photos/50/50?random2" alt="User Avatar" />
                                    </div>
                                </div>
                            ))}
                            {questions && currentQuestionIndex < questions.length && (
                                <div className="px-4 py-2">
                                    <div className="flex items-center mb-2">
                                        <img className="w-8 h-8 rounded-full mr-2" src="https://picsum.photos/50/50" alt="User Avatar" />
                                        <div className="font-medium text-black">Interviewer</div>
                                    </div>
                                    <div className="bg-purple-700 rounded-lg p-4 shadow mb-2 max-w-screen-md text-white">
                                        {!loading ?
                                        <div>
                                            <div>{questions[currentQuestionIndex]}</div>
                                        </div>
                                        :
                                        <div className="animate-pulse bg-gray-800 w-1/2 h-4 rounded-lg mt-2"></div>
                                        }    
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="bg-purple-900 px-4 py-2 flex-shrink-0 rounded-full my-2">
                            <div className="flex items-center">
                                <input
                                    className="w-full border rounded-full py-2 px-4 mr-2 text-black"
                                    type="text"
                                    placeholder="Type your response..."
                                    disabled={allQuestionsAnswered}
                                    value={transcript}
                                    onChange={(e) => setTranscript(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSend();
                                        }
                                    }}
                                />
                                <button
                                    className="bg-purple-500 hover:bg-purple-600 text-white font-medium py-2 px-4 rounded-full"
                                    onClick={handleSend}
                                    disabled={allQuestionsAnswered}
                                >
                                    Send
                                </button>
                                <button
                                    onClick={isListening ? handleStopListening : handleStartListening}
                                    className={`px-3 py-2 my- mx-2 font-semibold text-white rounded-xl transition duration-300 ${
                                        isListening ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
                                    }`}
                                >
                                    <img
                                        src={isListening ? micOff : micOn}
                                        alt={isListening ? 'Microphone Off' : 'Microphone On'}
                                        className="inline-block h-6 w-6" // Adjust size as needed
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Interviews;
