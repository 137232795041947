import React from "react";

const Policy = () => {
    return (
        <div class="bg-white py-24">
            <div class="container mx-auto px-4 py-8">
                <h1 class="text-3xl font-bold mb-4">Privacy Policy</h1>

                <p class="mb-4">
                    This privacy policy sets out how our website uses and protects any information that you give us when you use
                    this
                    website.
                </p>

                <h2 class="text-2xl font-bold mb-2">Information We Collect</h2>

                <p class="mb-4">
                    We collect the following information:
                </p>

                <ul class="list-disc list-inside mb-4">
                    <li>Your name and contact information</li>
                    <li>Your resume and cover letter</li>
                </ul>

                <h2 class="text-2xl font-bold mb-2">How We Use the Information</h2>

                <p class="mb-4">
                    We require this information to better analyze your job you are planning to apply for. If you do not click save my resume/cover letter for futre use on our services we will not save it in our system.
                </p>

                <h2 class="text-2xl font-bold mb-2">Security</h2>

                <p class="mb-4">
                    We are committed to ensuring that your information is secure. In order to prevent unauthorized access or
                    disclosure,
                    we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the
                    information we collect online.
                    Your sessions on this website are also encrypted.
                </p>

                <h2 class="text-2xl font-bold mb-2">Controlling Your Personal Information</h2>

                <p class="mb-4">
                    You may choose to restrict the collection or use of your personal information in the following ways:
                </p>

                <ul class="list-disc list-inside mb-4">
                    <li>Do not click on save resume or cover letter for future use. You can still access the services we provide and we will only store your documents for the time you are using the website.</li>
                    <li>We will not sell, distribute, or lease your personal information to third parties unless we have your
                        permission
                        or are required by law to do so. We may use your personal information to send you promotional
                        information
                        about
                        third parties which we think you may find interesting if you tell us that you wish this to happen.</li>
                    <li>You may request details of personal information which we hold about you. If you would like a copy of the
                        information held on you, please contact us for this matter.</li>
                    <li>If you believe that any information we are holding on you is incorrect or incomplete, please write to or
                        email
                        us as soon as possible at the above address. We will promptly correct any information found to be
                        incorrect.
                    </li>
                </ul>

                <p class="mb-4">
                    This privacy policy is subject to change without notice.
                </p>


                <h2 class="text-2xl font-bold mb-2">Disclamer</h2>

                <p class="mb-4">
                    <li>We do not gurantee the handling of your resume/cover letter data from the AI model our website uses. That information handling is our of our scope and we cannot gurantee the security for that part.
                    </li>
                    <li>
                        The images that are used on this website were generated from svgrepo.com. 
                    </li>
                </p>
            </div>
        </div>
    );
}

export default Policy;
