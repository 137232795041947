import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { FaArrowRight } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import Header from '../../components/header.js';
import theme from '../../styles/theme.js';
import resumeSvg from '../../assets/resume.svg';
import coverLetterSvg from '../../assets/cover-letter.svg';
import mockInterviewSvg from '../../assets/interview.svg';

const Services = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-200 text-gray-800">
            <Header />

            {/* Main Section */}
            <section className="pt-20 lg:pt-[120px] pb-12 lg:pb-[90px]">
                <div className="container mx-auto">
                    <div className="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
                        <motion.h2
                            variants={itemVariants}
                            initial="hidden"
                            animate={controls}
                            ref={ref}
                            className={`font-bold text-3xl sm:text-4xl md:text-[40px] ${theme.cyanTextColor} mb-4`}
                        >
                            Our Services
                        </motion.h2>
                        <motion.p
                            variants={itemVariants}
                            initial="hidden"
                            animate={controls}
                            className="text-base text-gray-700"
                        >
                            We help you land your dream job with our AI-powered suite of tools. Our services are designed to give you the edge you need to stand out to employers.
                        </motion.p>
                    </div>

                    {/* Service Cards */}
                    <div className="flex flex-wrap -mx-4">
                        {/* Resume Service Card */}
                        <motion.div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8"
                            variants={itemVariants}
                            initial="hidden"
                            animate={controls}
                        >
                            <ServiceCard
                                icon={resumeSvg}
                                title="Resume Wizard"
                                description="Get detailed feedback on your resume to make it stand out to employers. Our AI analyzes your resume and provides suggestions to improve its impact."
                                linkTo="/resume-editor"
                            />
                        </motion.div>

                        {/* Mock Interview Service Card */}
                        <motion.div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8"
                            variants={itemVariants}
                            initial="hidden"
                            animate={controls}
                        >
                            <ServiceCard
                                icon={mockInterviewSvg}
                                title="Mock Interviews"
                                description="Practice with AI-driven mock interviews to prepare for the real thing. Improve your confidence and performance with detailed feedback."
                                linkTo="/interviews"
                            />
                        </motion.div>

                        {/* Cover Letter Service Card */}
                        <motion.div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8"
                            variants={itemVariants}
                            initial="hidden"
                            animate={controls}
                        >
                            <ServiceCard
                                icon={coverLetterSvg}
                                title="Cover Letter Feedback"
                                description="Receive personalized suggestions to enhance your cover letter. Tailor your cover letter to highlight your strengths and fit for the job."
                                linkTo="/coverletter-editor"
                            />
                        </motion.div>
                        
                    </div>
                </div>
            </section>
        </div>
    );
};

// Reusable Service Card Component
const ServiceCard = ({ icon, title, description, linkTo }) => (
    <div className="p-10 md:px-7 xl:px-10 rounded-[20px] bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 text-gray-800">
        <div className={`w-[70px] h-[70px] flex items-center justify-center ${theme.cyanColor} rounded-2xl mb-8`}>
            <img src={icon} alt={title} className="max-w-96 max-h-14 object-contain" />
        </div>
        <h4 className={`font-semibold text-xl ${theme.cyanTextColor} mb-3`}>{title}</h4>
        <p className="text-gray-700 mb-6">{description}</p>
        <Link to={linkTo} className={`mt-auto ${theme.cyanColor} text-black font-semibold py-2 px-4 rounded-lg hover:${theme.cyanColorHover} transition-colors duration-300`}>
            Use Service <FaArrowRight className="inline-block ml-2" />
        </Link>
    </div>
);

export default Services;
