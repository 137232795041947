import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { motion } from 'framer-motion';
import 'react-tabs/style/react-tabs.css';
import theme from '../styles/theme.js';
import { serverUrl } from '../config';
import useAuthFetch from '../components/useAuthFetch.js';

// DocumentCard Component
const DocumentCard = ({ type, filename, onDownload }) => (
    <div className="bg-white rounded-lg shadow-md p-4 text-center md:text-left">
        <h4 className="text-base font-medium text-gray-900">{type}</h4>
        {/* <p className="text-sm text-gray-500 mt-1">{filename}</p> */}
        <button 
            className="mt-2 bg-cyan-500 hover:bg-cyan-600 text-white font-semibold py-1 px-2 rounded-md text-sm transition-colors duration-300"
            onClick={onDownload}
        >
            Download
        </button>
    </div>
);

// FileUpload Component
const FileUpload = ({ title, description, acceptedFileTypes, onDrop, onUpload, uploadedFilename }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptedFileTypes
    });

    return (
        <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-lg font-semibold mb-2">{title}</h2>
            <p className="text-sm text-gray-600 mb-4">{description}</p>
            <div {...getRootProps({ className: 'dropzone' })} className="relative bg-gray-50 rounded-lg p-6 cursor-pointer border-2 border-dashed border-gray-300 hover:border-gray-400">
                <input {...getInputProps()} />
                <p className="text-sm text-gray-500 text-center">Drag and drop or click to upload</p>
            </div>
            {uploadedFilename && <p className="text-sm text-gray-500 mt-2">File Uploaded: {uploadedFilename}</p>}
            <button
                className={`mt-4 bg-cyan-500 hover:bg-cyan-600 text-white font-semibold py-2 px-4 rounded-md text-sm transition-colors duration-300 ${!uploadedFilename ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={onUpload}
                disabled={!uploadedFilename}
            >
                Upload
            </button>
        </div>
    );
};

const Profile = () => {
    const [user, setUser] = useState({});
    const [resume, setResume] = useState('');
    const [coverLetter, setCoverLetter] = useState('');
    const [ruploadName, setRuploadName] = useState('');
    const [cluploadName, setCluploadName] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${serverUrl}/auth/userProfile`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.status === 401 || response.status === 422) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    return;
                } else {
                    const data = await response.json();
                    setUser(data);
                    setResume(data.resume !== 'None' && data.resume !== '' ? data.resume : null);
                    setCoverLetter(data.cover_letter !== 'None' && data.cover_letter !== '' ? data.cover_letter : null);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserProfile();

        const fetchAdminStatus = async () => {
            try {
                const response = await fetch(`${serverUrl}/isadmin`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setIsAdmin(data.is_admin);
                }
            } catch (error) {
                console.error('Error fetching admin status:', error);
            }
        };

        fetchAdminStatus();
    }, [token]);

    const handleDownload = (fileType) => {
        const url = fileType === 'Resume' ? `${serverUrl}/downloadResume` : `${serverUrl}/downloadCoverLetter`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.ok) {
                const contentDisposition = response.headers.get('Content-Disposition');
                const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : (fileType === 'Resume' ? 'resume' : 'cover_letter');
                return response.blob().then(blob => ({ blob, fileName }));
            }
            throw new Error('File not found');
        })
        .then(({ blob, fileName }) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url); // Clean up the object URL
        })
        .catch(error => console.error('Error downloading file:', error));
    };

    const handleFileChange = (file, type) => {
        if (type === 'Resume') {
            setResume(file); // Store the actual File object
            setRuploadName(file.name);
        } else {
            setCoverLetter(file); // Store the actual File object
            setCluploadName(file.name);
        }
    };

    const handleFileUpload = async (type) => {
        const file = type === 'Resume' ? resume : coverLetter;
    
        try {
            const formData = new FormData();
            formData.append('file', file); // Send the file as you named it in the backend
    
            const response = await fetch(`${serverUrl}/upload${type}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });
    
            if (response.ok) { // Check if the request was successful (status code 200-299)
                const data = await response.json();
                if (type === 'Resume') {
                    setResume(data.fileUrl);
                } else {
                    setCoverLetter(data.fileUrl);
                }
    
                // Reset file upload state after successful upload
                if (type === 'Resume') {
                    setRuploadName('');
                } else {
                    setCluploadName('');
                }
    
                // Refresh the page after successful upload
                window.location.reload();
            } else {
                // Handle the error response (e.g., show an error message)
                const errorData = await response.json();
                console.error(`Error uploading ${type}:`, errorData.error);
            }
        } catch (error) {
            console.error(`Error uploading ${type}:`, error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    };

    const onDrop = useCallback((acceptedFiles, type) => {
        handleFileChange(acceptedFiles[0], type);
    }, []);

    const { getRootProps: getResumeRootProps, getInputProps: getResumeInputProps } = useDropzone({
        onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'Resume'),
        accept: '.pdf,.doc,.docx'
    });

    const { getRootProps: getCoverLetterRootProps, getInputProps: getCoverLetterInputProps } = useDropzone({
        onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'CoverLetter'),
        accept: '.pdf,.doc,.docx'
    });

    const tabVariants = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 50 }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-200 text-gray-800 py-14">
            <div className="container mx-auto max-w-6xl py-10 px-4 sm:px-6 lg:px-8">
                <div className="bg-white rounded-lg shadow-lg p-8">

                    <h1 className={`text-5xl font-extrabold leading-9 tracking-tight mb-4 ${theme.cyanTextColor} text-center md:text-left`}>
                        Hello, {user.firstname}
                    </h1>

                    <Tabs>
                        <TabList className="flex flex-col md:flex-row mb-4 border-b border-gray-200">
                            <Tab className="py-5 px-4 text-2xl font-medium text-gray-500 hover:text-gray-700">
                                Profile Information
                            </Tab>
                            <Tab className="py-5 px-4 text-2xl font-medium text-gray-500 hover:text-gray-700">
                                Replace Documents
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className="mb-6">
                                <h3 className="text-xl font-medium mb-4 border-b pb-2">User Details</h3>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    
                                    <p className="text-gray-700">
                                        <strong>First Name:</strong> {user.firstname}
                                    </p>
                                    <p className="text-gray-700">
                                        <strong>Last Name:</strong> {user.lastname}
                                    </p>
                                    <p className="text-gray-700">
                                        <strong>Email:</strong> {user.email}
                                    </p>
                                </div>
                            </div>

                            {isAdmin && (
                                <div>
                                    <h3 className="text-xl font-medium mb-4 border-b pb-2">Admin Tools</h3>
                                    <button 
                                        className="bg-blue-500 hover:bg-blue-600 text-white my-4 font-semibold py-2 px-4 rounded-md transition-colors duration-300"
                                        onClick={() => window.location.href = '/dashboard'}
                                    >
                                        Admin Dashboard
                                    </button>
                                </div>
                            )}

                            <div className="mb-6">
                                {(resume || coverLetter) && (
                                    <div>
                                        <h3 className="text-xl font-medium mb-4 border-b pb-2">Documents</h3>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            {resume && (
                                                <DocumentCard 
                                                    type="Resume" 
                                                    filename={resume} 
                                                    onDownload={() => handleDownload('Resume')} 
                                                />
                                            )}
                                            {coverLetter && (
                                                <DocumentCard 
                                                    type="Cover Letter" 
                                                    filename={coverLetter} 
                                                    onDownload={() => handleDownload('CoverLetter')} 
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <h3 className="text-xl font-medium mb-4">Update Documents</h3>
                            <div className="flex flex-col space-y-6">
                                <FileUpload 
                                    title="Upload your Resume" 
                                    description="File should be of format .pdf or .docx. Maximum size 12MB."
                                    acceptedFileTypes=".pdf,.doc,.docx"
                                    onDrop={(files) => onDrop(files, 'Resume')}
                                    onUpload={() => handleFileUpload('Resume')}
                                    uploadedFilename={ruploadName}
                                />

                                <FileUpload 
                                    title="Upload your Cover Letter"
                                    description="File should be of format .pdf or .docx. Maximum size 12MB."
                                    acceptedFileTypes=".pdf,.doc,.docx"
                                    onDrop={(files) => onDrop(files, 'CoverLetter')}
                                    onUpload={() => handleFileUpload('CoverLetter')}
                                    uploadedFilename={cluploadName}
                                />
                            </div>
                        </TabPanel>
                    </Tabs>

                    <div className="mt-8 flex justify-center">
                        <button 
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md transition-colors duration-300"
                            onClick={handleLogout}
                        >
                            Logout
                        </button>
                    </div>

                </div> 
            </div>
        </div>
    );
};

export default Profile;
