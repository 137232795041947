import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './styles/index.css';
// Importing pages
import ResumeEditor from './pages/Resume';
import CoverLetterEditor from './pages/Coverletter';
import Home from './pages/Static/Home';
import ResumeViewer from './pages/Results/ResumeFeedback';
import Login from './pages/login';
import CoverLetterViewer from './pages/Results/CoverLetterFeedback';
import Interviews from './pages/Interviews';
import Feedback from './pages/Results/InterviewFeedback';
import Services from './pages/Static/services';
import Profile from './pages/profile';
import Signup from './pages/signup';
import PrivateRoute from './components/privateRoute';
import ErrorPage from './pages/Static/error';
import LoadingPage from './components/loading';
import Header from './components/header';
import Resume from './pages/Static/ResumeStatic'
import CoverLetter from './pages/Static/CoverLetterStatic'
import MockInterview from './pages/Static/InterviewStatic'
import Test from './pages/test'
import Dashboard from './pages/admin/dashboard';
import Policy from './pages/Static/policy';
import Footer from './components/footer';
import GoogleCallback from './components/GoogleCallback';
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {/* Accessible without login */}
        <Route index path='/' element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path='/services' element={<Services />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/cover' element={<CoverLetter/>}/>
        <Route path='interview' element={<MockInterview/>}/>
        <Route path='/resume' element={<Resume/>} />

        <Route path='/policy' element={<Policy/>}/>
        <Route path='/test' element={<Test/>}/>

        {/* Accessible after login */}
        <Route path="/resume-editor" element={<PrivateRoute element={ResumeEditor} />} />
        <Route path="/coverletter-editor" element={<PrivateRoute element={CoverLetterEditor} />} />
        <Route path="/resumeViewer" element={<PrivateRoute element={() => <ResumeViewer responseData={JSON.parse(localStorage.getItem('responseData'))} />} />} />
        <Route path='/coverLetterViewer' element={<PrivateRoute element={CoverLetterViewer} />} />
        <Route path="/auth/callback" element={<GoogleCallback />} />
        <Route path='/interviews' element={<PrivateRoute element={Interviews} />} />
        <Route path='/feedback' element={<PrivateRoute element={Feedback} />} />
        {/* <Route path='/loading' element={<PrivateRoute element={Loading} />} /> */}
        <Route path='/profile' element={<PrivateRoute element={Profile} />} />
        <Route path='*' element={<ErrorPage />} />
        <Route path='/loading' element={<LoadingPage />} />

        {/* Admin Routes */}
        <Route path='/dashboard' element={<Dashboard/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
