import React from "react";

const Footer = () => {
    return (
        <footer className="border-t border-customBlue">
            <div className="flex flex-col self-stretch px-8 pb-4 w-full bg-white max-md:px-5 max-md:max-w-full">
                <div className="flex gap-5 mt-3 w-full max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                    <div className="flex-auto self-start text-black text-lg leading-9 max-md:text-base max-sm:text-sm">
                        The Career Lab
                    </div>
                    <div className="flex flex-1 gap-2 justify-end text-lg leading-9 text-black max-md:text-base max-sm:text-sm">
                        <a href="/policy">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
