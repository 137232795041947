import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../assets/account.svg';
import logo from '../assets/logo2.svg';
import theme from '../styles/theme.js';
import account from '../assets/account.svg';  // Ensure the correct import for the account SVG

export default function Header() {
    const token = localStorage.getItem('token');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const getNavLinkClass = (paths) => {
        return paths.includes(location.pathname)
            ? `text-gray-700 transition hover:bg-gray-100 hover:text-black px-3 py-2 rounded border-b-2 ${theme.cyanBorderColor}`
            : `text-gray-700 transition hover:bg-gray-100 hover:text-black px-3 py-2 rounded hover:border-b-2 ${theme.cyanBorderColorHover}`;
    };

    return (
        <div>
            <header className="bg-white w-screen mx-auto fixed z-50">
                <div className="rounded-xl mx-auto flex h-16 max-w-screen items-center px-4 sm:px-6 lg:px-8">
                    <a className="text-cyan-400 flex" href="/">
                        <img src={logo} alt="Logo" className="h-8" />
                        <span className="text-lg sm:text-xl md:text-2xl py-1 mx-2 text-blue-600 font-bold">The Career Lab</span>
                    </a>
                    
                    <nav className="hidden md:flex flex-1 justify-center gap-6 text-sm">
                        <ul className="flex items-center gap-6 text-base">
                            <li>
                                <a className={getNavLinkClass(['/'])} href="/"> Home </a>
                            </li>
                            <li>
                                <a className={getNavLinkClass(['/interviews', '/interview'])} href="/interview"> Interview Me </a>
                            </li>
                            <li>
                                <a className={getNavLinkClass(['/resume','/resume-editor'])} href="/resume"> Resume </a>
                            </li>
                            <li>
                                <a className={getNavLinkClass(['/cover','/coverletter-editor'])} href="/cover"> Cover Letter </a>
                            </li>
                        </ul>
                    </nav>
                    
                    <div className="flex items-center gap-4 ml-auto">
                        {token ? (
                            <a href="/profile" className="text-gray-700 transition hover:text-black">
                                <img src={account} alt="Profile" className="h-6 w-6" />
                            </a>
                        ) : (
                            <div className="sm:flex sm:gap-4">
                                <a
                                    className="block rounded-md bg-gray-100 px-5 py-2.5 text-sm font-medium text-black transition hover:bg-cyan-400"
                                    href="/login"
                                >
                                    Login
                                </a>

                                <a
                                    className="hidden sm:block rounded-md bg-gray-100 px-5 py-2.5 text-sm font-medium text-black transition hover:bg-cyan-400"
                                    href="/signup"
                                >
                                    Sign Up
                                </a>
                            </div>
                        )}

                        <button
                            className="block rounded bg-gray-100 p-2.5 text-gray-600 transition hover:text-gray-600/75 md:hidden ml-0"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            <span className="sr-only">Toggle menu</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </header>

            {isMenuOpen && (
                <nav aria-label="Global" className="block md:hidden bg-white border-t border-gray-200">
                    <ul className="flex flex-col items-center gap-6 text-sm py-4">
                        <li>
                            <a className={getNavLinkClass(['/'])} href="/"> Home </a>
                        </li>
                        <li>
                            <a className={getNavLinkClass(['/resume-editor'])} href="/resume-editor"> Resume </a>
                        </li>
                        <li>
                            <a className={getNavLinkClass(['/coverletter-editor'])} href="/coverletter-editor"> Cover Letter </a>
                        </li>
                        <li>
                            <a className={getNavLinkClass(['/interviews'])} href="/interviews"> Interviews </a>
                        </li>
                    </ul>
                </nav>
            )}
        </div>
    );
}
