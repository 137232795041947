// theme.js

// Define your theme object here
const theme = {
    // Define color variable at the top
    cyanColor: 'bg-blue-400',
    cyanColorHover: 'hover:bg-cyan-300',
    cyanTextColor: 'text-blue-400',
    cyanBorderColor: 'border-blue-400',
    cyanBorderColorHover: 'hover:border-blue-300',
};

// Export the theme object
export default theme;