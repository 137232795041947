import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import DiffMatchPatch from 'diff-match-patch';
import '../../styles/index.css';

function Feedback() {
    const [highlight, setHighlight] = useState(true);

    function extractAndParseJson(dataString) {
        try {
            const jsonString = dataString.match(/{.*}/s)[0]; // Extract the JSON string between { and }
            const jsonObject = JSON.parse(jsonString); // Parse the JSON string into a JavaScript object
            return jsonObject;
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return null;
        }
    }

    const location = useLocation();
    let data = location.state; // Access the data here
    data = extractAndParseJson(data);
    console.log(data);
    const feedbackRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => feedbackRef.current,
    });

    function diffStrings(original, fix) {
        const dmp = new DiffMatchPatch();
        const diffs = dmp.diff_main(original, fix);
        dmp.diff_cleanupSemantic(diffs);
        return diffs.map((part, index) => {
            const [type, text] = part;
            if (type === DiffMatchPatch.DIFF_INSERT) {
                return <span key={index} className="bg-green-200 text-green-800">{text}</span>;
            } else if (type === DiffMatchPatch.DIFF_DELETE) {
                return <span key={index} className="bg-red-200 text-red-800 line-through">{text}</span>;
            } else {
                return <span key={index}>{text}</span>;
            }
        });
    }

    function renderFeedback(data) {
        if (!data || !data.target_sentences || !data.Fix || !data.reason) {
            return null;
        }

        const feedbackItems = data.target_sentences.map((sentence, index) => {
            const fix = data.Fix[index];
            const reason = data.reason[index];

            if (!sentence || !fix || !reason || sentence === fix) {
                return null;
            }

            return (
                <div className="py-6 space-y-4 md:grid md:grid-cols-12 md:gap-8 md:space-y-0" key={index}>
                    <h3 className="font-semibold text-blue-700 md:col-span-4">{sentence}</h3>
                    <h3 className="font-semibold text-right text-blue-700 md:col-span-4">
                        {highlight ? diffStrings(sentence, fix) : fix}
                    </h3>
                    <div className="md:col-span-4">
                        <p className="text-gray-700">{reason}</p>
                    </div>
                </div>
            );
        }).filter(item => item !== null);

        if (feedbackItems.length === 0) {
            return (
                <div className="py-6 space-y-4 text-center">
                    <p className="text-gray-700">I think your resume is perfect as it is.</p>
                </div>
            );
        }

        return (
            <>
                <div className="py-6 md:grid md:grid-cols-12 md:gap-8 md:space-y-0 text-center">
                    <h3 className="font-semibold text-blue-700 md:col-span-4">Original Sentence</h3>
                    <div className="justify-between items-center md:col-span-4">
                        <h3 className="font-semibold text-blue-700">Suggested Fix</h3>
                        <button
                            onClick={() => setHighlight(!highlight)}
                            className="bg-blue-400 text-black font-semibold py-1 px-2 rounded-lg hover:bg-blue-300 transition-colors duration-300 my-2"
                        >
                            {highlight ? 'Turn Off Highlight' : 'Turn On Highlight'}
                        </button>
                    </div>
                    <h3 className="font-semibold text-blue-700 md:col-span-4">Reason</h3>
                </div>
                {feedbackItems}
            </>
        );
    }

    return (
        <div className="bg-blue-50 text-gray-900 min-h-screen py-24">
            <div className="container mx-auto py-10" ref={feedbackRef}>
                <div className="text-center mb-10">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-blue-500">Resume Feedback</h1>
                    <p className="mt-4 text-lg font-light text-gray-600">Your resume has been analyzed. Please review the feedback below to tailor it to the job description.</p>
                </div>
                <section className="bg-white rounded-lg shadow-lg p-8">
                    <div className="divide-y divide-gray-200">
                        {renderFeedback(data)}
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Feedback;
