import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

function Feedback() {
    const location = useLocation();
    const data = location.state; // Access the data here
    console.log(data);

    const feedbackRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => feedbackRef.current,
    });

    return (
        <div className="bg-purple-50 text-gray-900 min-h-screen py-24">
            <div className="container mx-auto py-10" ref={feedbackRef}>
                <div className="text-center mb-10">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-purple-500">Interview Feedback</h1>
                    <p className="mt-4 text-lg font-light text-gray-600">Your responses are analyzed. Please review them carefully. Good luck!</p>
                </div>
                <section className="bg-white rounded-lg shadow-lg p-8">
                    <div className="divide-y divide-gray-200">
                        {data.questions.map((question, index) => (
                            <div className="py-6 space-y-4 md:grid md:grid-cols-12 md:gap-8 md:space-y-0" key={index}>
                                <h3 className="font-semibold text-purple-700 md:col-span-5">{data.questions[index]}</h3>
                                <div className="md:col-span-7">
                                    <p className="text-gray-700"><strong>Original Response:</strong> {data.responses[index]}</p>
                                    <br />
                                    <p className="text-gray-700 border-l-4 border-purple-700 pl-2"><strong>Feedback:</strong> {data.feedback[index]}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
            <div className="fixed bottom-4 right-4">
                <button
                    onClick={handlePrint}
                    className="bg-purple-700 text-white font-semibold py-2 px-4 rounded-lg hover:bg-purple-600 transition-colors duration-300"
                >
                    Save as PDF
                </button>
            </div>
        </div>
    );
}

export default Feedback;
